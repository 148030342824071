import { StatusCodes } from "http-status-codes";
import { z } from "zod";

export class SurfaceableZodError extends Error {
  status: number;
  surface: boolean;

  constructor(data: { message: string; errors: z.ZodIssue[]; status: number }) {
    super();

    const zodErrors = data.errors.reduce((acc, err) => {
      acc += `Error: (${err.message}) at ${[...err.path, ""]}.`;
      return acc;
    }, "");

    this.surface = true;
    this.message = `${data.message} ${zodErrors}`;
    this.status = data.status;
    Error.captureStackTrace(this, SurfaceableZodError);
  }
}

export class SurfaceableStatusError extends Error {
  status: number;
  surface: boolean;

  constructor(data: { message: string; status: number }) {
    super();

    this.surface = true;
    this.message = data.message;
    this.status = data.status;
    Error.captureStackTrace(this, SurfaceableStatusError);
  }
}

// makes these errors identifiable as surface-able to front end
export class SurfaceableError extends Error {
  status: number;

  constructor(data: string | { stack?: string; message: string; status: number }) {
    super();
    if (typeof data === "string") {
      this.message = data;
      this.status = StatusCodes.INTERNAL_SERVER_ERROR;
    } else {
      this.message = data.message;
      this.status = data.status;

      // if error provided we use that error's stack to trace bugs more easily
      if (data.stack) {
        this.stack = JSON.stringify(data.stack, null, 2);
      } else {
        Error.captureStackTrace(this, SurfaceableError);
      }
    }
  }
}

export class OperationalError extends Error {
  metadata: any | undefined;

  constructor(message: string, meta: any | undefined = undefined) {
    super();
    this.message = message;
    this.metadata = meta;
  }
}
